main {
    flex: 1;
    display: flex;
}

.left {
    flex: 50%;
    font-family: monospace;
    white-space: pre;
    padding: 20px;
    resize: none;
    overflow: auto;
    background-color: #ffffff;
    /* -- start of show line numbers -- */
    /* background: url(line.png);
    background-attachment: local;
    background-repeat: no-repeat;
    padding-left: 35px;
    padding-top: 10px; */
    /* -- end of show line numbers -- */
}

.left.error {
    outline-color: red;
}

.right {
    flex: 50%;
    font-family: monospace;
    white-space: pre;
    padding: 20px;
    resize: none;
    overflow: auto;
    background-color: #f9f9f9;
}

@media screen and (max-width: 1024px) {
    main {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 600px) {
    .footer img {
        display: none;
    }
}

pre {
    margin: 0px;
}

textarea:focus {
    outline-color: black;
    outline-width: 2px;
    outline-offset: -2px;
    outline-style: solid;
}