.codeexamples {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.codeexamples pre {
    padding: 10px;
    background-color: #f7fcfa;
    border: 1px solid gray;
    border-radius: 4px;
    white-space: break-spaces;
    word-break: break-all;
}

.codeexamples .caption {
    font-size: 0.9em;
    font-style: italic;
    color: rgb(53, 53, 53);
}

.codeexamples a:link {
    color: #2e6a36;
    font-weight: bolder;
}

.codeexamples a:visited {
    color: #2e6a36;
    font-weight: bolder;
}