.header h1 {
    font-size: 1em;
    font-weight: normal;
    margin: 0px;
}

.header h2 {
    font-size: 0.7em;
    font-weight: normal;
    margin: 0px;
    padding-left: 0.2em;
}

.leftside {
    float: left;
    width: 50%;
}

@media screen and (max-width: 1024px) {
    .leftside {
        width: 100%;
    }
    .rightside {
        width: 100%;
    }
    .menuitem {
        display: block;
    }
}

.rightside {
    float: left;
    margin-top: 4px;
    width: 50%;
    /* margin-left: 50%; */
    /* margin-right: 10em; */
}

.menuitem {
    margin-right: 10px;
    text-transform: uppercase;
    font-size: 0.7em;
    /* border: 1px solid gray; */
    padding: 4px;
    border-radius: 8px;
    white-space: nowrap;
}

a {
    font-size: 1em;
}

a.item {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

a:link {
    color: #1c211b;
}

a:visited {
    color: #1c211b;
}

a:hover {
    color: #1c211b;
}

a:active {
    color: #1c211b;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:active {
    text-decoration: underline;
}