.footeritem {
    font-size: 0.6em;
    display: block;
    text-align: left;
    text-transform: uppercase;
}

a:link {
    color: #1c211b;
}

a:visited {
    color: #1c211b;
}

.column {
    float: left;
    width: 25%;
}

@media screen and (max-width: 600px) {
    .column {
        float: left;
        width: 100%;
    }
}