.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header {
    background-color: #bde4d2;
    background: linear-gradient(90deg, #bde4d2, #add4b2);
    padding: 10px;
    font-size: 1.5em;
    color: #1c211b;
    text-shadow: 1px 1px 3px rgba(128, 128, 128, 50%);
}

.footer {
    background-color:#bde4d2;
    background: linear-gradient(180deg, #bde4d2, #add4b2);
    padding: 10px;
    font-size: 1.5em;
    text-align: center;
    min-height: 20px;
}

main {
    flex: 1;
    display: flex;
}